import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import './App.css';

// const baseURL = 'http://localhost:5000/api' // NOTE: for dev only
const baseURL = `${window.location.origin}/api`

export default function App() {
  return (
    <Router>
      <div className='App'>
        <h3><Link to="/">Home</Link> | &nbsp;
          <Link to="/legend">Visual Key</Link> | &nbsp;
          <Link to="/search">Search</Link> | &nbsp;
          <Link to={randomLink}>Random</Link></h3>

        <Switch>
          <Route path="/rv/:m.:s" component={Suktas} />
          <Route path="/legend" component={Legend} />
          <Route path="/search" component={Search} />
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <div className='Home'>
    <h2>Welcome to veda.dev!</h2>
    <h4>A research & education tool for Vedic Sanskrit literature</h4>
    <h5>1. Visit URL for a maṇḍala & sūkta (e.g. <a href='https://veda.dev/rv/1.174'>veda.dev/rv/1.174</a>)</h5>
    <h5>2. Hover over padas (words) for morphological & semantic markup</h5>
    <h5>3. Learn & interpret Vedic Sanskrit faster than ever before!</h5>
    <p>We make it easy to access the whole ṛgveda pada pāṭha through morphological & semantic annotation. Specifically,
      for each pada (word), we provide its lemma, broad part-of-speech, and definitions. For verbs, we indicate
      <b> [person.number.tense-aspect-mood]</b>; for nouns and adjectives, we indicate <b> [case.number.gender]</b>.
    </p>
    <p>We currently only provide pada pāṭha (without accents) because we are prioritizing morphosemantic
      interpretability rather than rather than poetic interpretability. However, we do plan on adding accented samhita pāṭha,
      Yajurveda, as well as other texts & tools in the future. Before interpreting the ṛgveda, please ensure you have a firm grasp of Sanskrit linguistics,
      Hindu philosophy, & Indian history.
    </p>
    <hr />
    <h5><b>Based on <a href='https://github.com/sanskrit-texts/rigveda'>dataset</a>:</b> Oliver Hellwig, Heinrich Hettrich, Ashutosh Modi, Manfred Pinkal: Multi-layer Annotation of the Rigveda. In: Proceedings of the LREC, 2018.</h5>
    <h5>Other useful tools: <a href='https://sri-aurobindo.in/workings/matherials/rigveda/'>Aurobindo.in</a>, <a href='https://www.sacred-texts.com/hin/rigveda/index.htm'>Sacred Texts</a>, <a href='https://meluhha.com/rv/'>Meluhha</a>, <a href='https://www.learnsanskrit.cc/'>Sanskrit Dictionary</a>, <a href='https://learnsanskrit.org'>Learn Sanskrit</a> </h5>
    <EmailCapture marginBottom={'0%'} />
    <h5>Built with ❤️ by <a href='https://rpandey.tech'>Rohan</a></h5>
    <h5><a href='https://paypal.me/khoomeik'>Dakṣiṇā (Donate)</a></h5>
  </div>;
}

function Legend() {
  return <div className='Legend'>
    <p>Textual styles compose together to visually signify morphological properties
      of Sanskrit words in this tool. Use this guide as a cheatsheet to get better
      at skimming text and learning Sanskrit morphology by Vedic example. In any sūkta,
      you can always hover over a word to get its complete morphological annotation.
    </p>
    <h2>Nominal Part of Speech</h2>
    <p>
      <span className='sabda' style={{ borderTop: posStyle.NOUN }}>Noun</span> &nbsp;
      <span className='sabda' style={{ borderTop: posStyle.ADJ }}>Adjective</span> &nbsp;
      <span className='sabda' style={{ borderTop: posStyle.VN }}>Verbal Noun</span> &nbsp;
    </p>
    <h2>Nominal Case</h2>
    <p>
      <span className='sabda' style={{ background: caseStyle.NOM }}>Nominative</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.ACC }}>Accusative</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.DAT }}>Dative</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.GEN }}>Genitive</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.INS }}>Instrumental</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.LOC }}>Locative</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.ABL }}>Ablative</span> &nbsp;
      <span className='sabda' style={{ background: caseStyle.VOC }}>Vocative</span> &nbsp;
    </p>
    <h2>Nominal Gender</h2>
    <p>
      <span className='sabda' style={genderStyle.M}>Masculine</span> &nbsp;
      <span className='sabda' style={genderStyle.F}>Feminine</span> &nbsp;
      <span className='sabda' style={genderStyle.N}>Neuter</span> &nbsp;
    </p>
    <h2>Number</h2>
    <p>
      <span className='sabda' style={{ borderBottom: numberStyle.S }}>Singular</span> &nbsp;
      <span className='sabda' style={{ borderBottom: numberStyle.D }}>Dual</span> &nbsp;
      <span className='sabda' style={{ borderBottom: numberStyle.P }}>Plural</span> &nbsp;
    </p>
    <h2>Verbal Person</h2>
    <p>
      <span className='sabda' style={{ color: 'white', background: personStyle[1] }}>First</span> &nbsp;
      <span className='sabda' style={{ color: 'white', background: personStyle[2] }}>Second</span> &nbsp;
      <span className='sabda' style={{ color: 'white', background: personStyle[3] }}>Third</span> &nbsp;
    </p>
  </div>
}

function FiltersForm(props) {
  const handleSubmit = e => {
    e.preventDefault();
    props.setFilters({ ...(props.filters), load: !props.filters.load });
  }

  return (
    <form className='filterForm' onSubmit={handleSubmit}>
      <input type="text" placeholder="Lemma" onChange={e => props.setFilters({ ...(props.filters), lemma: e.target.value })} />

      <select id="pos" name="Part of Speech" onChange={e => props.setFilters({ ...(props.filters), pos: e.target.value })}>
        <option value="" selected>Part of Speech</option>
        <option value="NOUN">Noun</option>
        <option value="ADJ">Adjective</option>
        <option value="VN">Verbal Noun</option>
        <option value="VERB">Verb</option>
        <option value="ADV">Adverb, other</option>
      </select>

      <select id="case" name="Case" onChange={e => props.setFilters({ ...(props.filters), case: e.target.value })}>
        <option value="" selected>Case</option>
        <option value="NOM">Nominative</option>
        <option value="ACC">Accusative</option>
        <option value="DAT">Dative</option>
        <option value="GEN">Genitive</option>
        <option value="INS">Instrumental</option>
        <option value="LOC">Locative</option>
        <option value="ABL">Ablative</option>
        <option value="VOC">Vocative</option>
      </select>

      <select id="gender" name="Gender" onChange={e => props.setFilters({ ...(props.filters), gender: e.target.value })}>
        <option value="" selected>Gender</option>
        <option value="M">Male</option>
        <option value="F">Female</option>
        <option value="N">Neuter</option>
      </select>

      <select id="number" name="Number" onChange={e => props.setFilters({ ...(props.filters), number: e.target.value })}>
        <option value="" selected>Number</option>
        <option value="S">Singular</option>
        <option value="D">Dual</option>
        <option value="P">Plural</option>
      </select>

      <select id="person" name="Person" onChange={e => props.setFilters({ ...(props.filters), person: e.target.value })}>
        <option value="" selected>Person</option>
        <option value={1}>First</option>
        <option value={2}>Second</option>
        <option value={3}>Third</option>
      </select>

      <select id="tense" name="Tense System" onChange={e => props.setFilters({ ...(props.filters), tense: e.target.value })}>
        <option value="" selected>Tense System</option>
        <option value="present">Present</option>
        <option value="aorist">Aorist</option>
        <option value="perfect">Perfect</option>
        <option value="future">Future</option>
      </select>

      <select id="mood" name="Mood" onChange={e => props.setFilters({ ...(props.filters), mood: e.target.value })}>
        <option value="" selected>Mood</option>
        <option value="indicative">Indicative</option>
        <option value="imperfect">Imperfect</option>
        <option value="subjunctive">Subjunctive</option>
        <option value="optative">Optative</option>
        <option value="imperative">Imperative</option>
        <option value="periphrastic">Periphrastic</option>
        <option value="conditional">Conditional</option>
      </select>

      <select id="voice" name="Voice" onChange={e => props.setFilters({ ...(props.filters), voice: e.target.value })}>
        <option value="" selected>Voice</option>
        <option value="active">Active</option>
        <option value="mediopassive">Mediopassive</option>
      </select>

      <br />
      <input type="submit" value="Search" />
    </form>
  );
}

function Search(props) {
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState({ load: false });
  const [citationsOpen, toggleCitationsOpen] = useState({});

  useEffect(async () => {
    let { load, ...mainFilters } = filters;
    if (mainFilters.length == 0 || Object.values(mainFilters).every(s => s.length == 0)) {
      setResults([]);
      return;
    }

    let res = await fetch(`${baseURL}/filterPadas`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(mainFilters)
    })

    res = await res.json();
    await setResults(res);
    toggleCitationsOpen({});
    ReactTooltip.rebuild();
  }, [filters.load]);

  return <div>
    <FiltersForm filters={filters} setFilters={setFilters} />
    <table>
      {results.map((sabda, k) =>
        <tr key={k}>
          <td><a href={`/rv/${sabda.book}.${sabda.chapter}`}>{`${sabda.book}.${sabda.chapter}.${sabda.strophe}`}</a>: &nbsp;</td>
          <td><span className='sabda' style={wordDataStyle(sabda)} data-tip={wordDataStr(sabda, true)} data-multiline={true}>{sabda.pada}</span></td>
          <td>
            <span className='citationUses' onClick={() => 
                toggleCitationsOpen({ ...citationsOpen, [k]: k in citationsOpen ? !citationsOpen[k] : true })
              }>
              {sabda.citations ? 
                (num => num > 1 ? `${num} uses` : `${num} use`)
                ((sabda.citations.match(/,/g) || []).length + 1) 
              : 0}
            </span>
            <br />
            {k in citationsOpen && citationsOpen[k] ?
              sabda.citations.split(', ').map(cit => 
                <span><a href={`/rv/${cit.split('.').slice(0, 2).join('.')}`}>{cit}</a>, </span>
              ) : null}
          </td>
        </tr>
      )}
    </table>
    <ReactTooltip />
  </div>
}

const emailUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdLLREiJi4JG9jMuAqj3TFok4jJY5ziyfCm0l5m147_vYIFYA/viewform?usp=pp_url&entry.2102506398=`

function EmailCapture(props) {
  const [email, setEmail] = useState('');
  return (
    <form style={{ marginBottom: props.marginBottom }}>
      <input style={{ width: '15em' }} type="text" placeholder="Email" onChange={e => setEmail(e.target.value)} />
      <input type="submit" value="Join the community!" onClick={() => window.open(emailUrl + email, '_blank').focus()} />
    </form>)
}

function Suktas(props) {
  let { m, s } = props.match.params;
  const [sukta, setSukta] = useState([]);
  useEffect(async () => {
    let res = await fetch(`${baseURL}/${m}/${s}`);
    res = await res.json();
    setSukta(res);
    ReactTooltip.rebuild();
  }, [m, s]);

  if (sukta.length > 0) {
    return <div>
      <h1>Ṛgveda {m}.{s}</h1>
      {sukta.map((rc, i) =>
        <p className='rc' key={i}>
          <h4 style={{ 'display': 'inline' }}>{i + 1}. </h4>
          {rc.map((paada, j) =>
            <span className='paada' key={j}>
              {paada.map((sabda, k) =>
                <span key={k}>
                  <span className='sabda' style={wordDataStyle(sabda)} data-tip={wordDataStr(sabda)} data-multiline={true}>{sabda.pada}</span>
                  &nbsp;
                </span>
              )}
              |{j == rc.length - 1 ? '|' : null}<br />
            </span>
          )}
        </p>
      )}
      <ReactTooltip />
      <hr />
      <h6>
        {Object.entries(linkGenerator(m, s)).map(([title, url], i) =>
          <span key={i}><a href={url}>{title}</a> {i < 3 ? '|' : null} </span>
        )}
      </h6>
      <EmailCapture marginBottom={'10%'} />
    </div>
  } else {
    return <div>
      <h2>Loading...</h2>
      <h3>or I couldn't fulfill your request.</h3>
      <h4>Check the URL again and make sure it's a correct mandala.sukta in the rgveda.</h4>
    </div>
  }
}

const mandalaLens = [191, 43, 62, 58, 87, 75, 104, 103, 114, 191];
function randomLink() {
  const mandala = Math.floor(Math.random() * 10);
  const sukta = Math.floor(Math.random() * mandalaLens[mandala]);
  return `/rv/${mandala + 1}.${sukta + 1}`;
}

function linkGenerator(m, s) {
  let links = {};
  links['Meluhha'] = `https://meluhha.com/rv/find.pl?q=${m}.${s}`;

  const padM = ("0" + m).slice(-2);
  const padS = ("00" + s).slice(-3);

  links['Aurobindo.in'] = `https://sri-aurobindo.in/workings/matherials/rigveda/${padM}/${padM}-${padS}.htm`;
  links['Sacred Texts (English)'] = `https://www.sacred-texts.com/hin/rigveda/rv${padM}${padS}.htm`;
  links['Sacred Texts (Sanskrit)'] = `https://www.sacred-texts.com/hin/rvsan/rv${padM}${padS}.htm`;

  return links;
}

function wordDataStr(obj, search = false) {
  if (obj.pos === 'NOUN' || obj.pos === 'ADJ') {
    return `${obj.lemma} (${obj.pos}): ${caseList[obj.case]}.${numberList[obj.number]}.${genderList[obj.gender]}
    <br>${obj.defs}`
  } else if (obj.pos === 'VERB') {
    if (search) {
      return `${obj.lemma} ${obj.root ? '√' + obj.root : ''} (${obj.pos}): ${obj.person}.${numberList[obj.number]}.${tamList[obj.tense]}.${tamList[obj.mood]}.${tamList[obj.voice]}
      <br>${obj.defs}`
    }
    return `${obj.lemma} ${obj.root ? '√' + obj.root : ''} (${obj.pos}): ${obj.person}.${numberList[obj.number]}.${obj.tam}
    <br>${obj.defs}`
  } else if (obj.pos === 'VN') {
    if (search) {
      return `${obj.lemma} ${obj.root ? '√' + obj.root : ''} (${obj.pos}): ${caseList[obj.case]}.${numberList[obj.number]}.${genderList[obj.gender]}.${tamList[obj.tense]}.${tamList[obj.mood]}.${tamList[obj.voice]}
      <br>${obj.defs}`
    }
    return `${obj.lemma} ${obj.root ? '√' + obj.root : ''} (${obj.pos}): ${caseList[obj.case]}.${numberList[obj.number]}.${genderList[obj.gender]}.${obj.tam}
    <br>${obj.defs}`
  } else if (obj.pos === 'ADV') {
    return `${obj.lemma} (${obj.pos})
    <br>${obj.defs}`
  }
}

function wordDataStyle(obj) {
  let style = {};
  if (obj.pos === 'NOUN' || obj.pos === 'ADJ' || obj.pos === 'VN') {
    style['background'] = caseStyle[caseList[obj.case]];
    style['borderBottom'] = numberStyle[numberList[obj.number]]
    style = { ...style, ...genderStyle[genderList[obj.gender]] }
    style['borderTop'] = posStyle[obj.pos]
  } else if (obj.pos === 'VERB') {
    style['color'] = 'white';
    style['background'] = personStyle[obj.person];
    style['borderBottom'] = numberStyle[numberList[obj.number]]
  }

  return style;
}

const caseList = ['∅', 'NOM', 'VOC', 'ACC', 'INS', 'DAT', 'ABL', 'GEN', 'LOC'];
const numberList = ['∅', 'S', 'D', 'P'];
const genderList = ['∅', 'M', 'F', 'N', '∅'];
const tamList = {
  present: 'PRES',
  perfect: 'PERF',
  aorist: 'AOR',
  future: 'FUT',
  indicative: 'IND',
  imperfect: 'IMPERF',
  subjunctive: 'SUBJ',
  optative: 'OPT',
  imperative: 'IMP',
  periphrastic: 'PERI',
  conditional: 'COND',
  active: 'ACT',
  mediopassive: 'MED'
}

const caseStyle = {
  '∅': null,
  'NOM': 'lightcoral',
  'ACC': 'orange',
  'DAT': 'khaki',
  'GEN': 'lightgreen',
  'INS': 'turquoise',
  'LOC': 'lightblue',
  'ABL': 'plum',
  'VOC': 'lightgrey',
}

const personStyle = {
  '∅': null,
  1: 'darkred',
  2: 'darkolivegreen',
  3: 'steelblue'
}

const numberStyle = {
  '∅': null,
  'S': null,
  'D': '3px black dotted',
  'P': '3px black solid'
}

const genderStyle = {
  '∅': null,
  'M': null,
  'F': { 'fontStyle': 'italic' },
  'N': { 'fontWeight': 'bold' },
}

const posStyle = {
  'NOUN': null,
  'ADJ': '3px black solid',
  'VN': '3px black dashed'
}
